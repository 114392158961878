import './index.scss';

function index() {
  document.title = "玩家守则 | WhCraft";

  return (
    <div className="eula">
		<section>
			<h2>玩家守则</h2>
      <p>服务器的玩家守则</p>
		</section>

		<main>
			<article className="container">
        <h1>概述</h1>
        <p>本 EULA 是您和我们（WhCraftMC）之间达成的协议，您应该阅读完整内容。您与Mojang AB 和微软公司或（如适用）之间达成的法律协议（<a href="https://www.minecraft.net/zh-hans/eula" target="_blank" rel="noreferrer">Eula</a>）在此仍然生效。</p>

        <h1>玩家守则</h1>
        <p>这是我们的社区准则。</p>
        <h2>您可以和不可以用 MINECRAFT 软件和内容在我们服务器做的事情</h2>
        <p>您可以畅玩我们的游戏。</p>
        <p>但是，除非我们明确同意，否则您不得进行以下的任意行为：</p>
        <li>将我们创建的任何内容用于商业用途；</li>
        <li>试图通过我们创建的任何内容赚钱；</li>
        <li>允许其他人以不公平或不合理的方式访问我们创建的任何内容；</li>
        <li>利用我们创建的内容传播非法、不符合道德伦理的内容；</li>
        <li>利用我们创建的内容的漏洞。</li>

        <h2>网络安全</h2>
        <p>如果您在我们的游戏中与人们交谈，请当心。无论是您还是我们都很难确定人们说的哪些话是真的，甚至很难确定人们实际上是否与其所声称的身份相符。在披露有关您自己的信息之前，始终应该三思。</p>

        <h2>我们的价值观</h2>
        <p>包容的精神存在于我们的价值观，它是维持充满活力和热情的社区的关键。</p>
        <li>Minecraft 适合每个人</li>
        <li>与他人一起玩游戏应该是安全和包容的</li>
        <li>这里没有仇恨的容身之地</li>

        <h2>隐私</h2>
        
        <p>本隐私条款适用于 Minecraft 服务器由服务器提供商有限公司（以下简称 "我们" 或 "服务器提供商”）提供和使用。在使用我们的服务器和相关服务时，您同意本隐私条款。</p>
        <li>1.我们收集关于您和使用我们的服务器和相关服务的信息，包括：
          <p>a. 您提供的信息：当我们您注册帐户或提供个人资料时，我们将收集您的电子邮件地址、密码等。此外，我们还收集关于您游戏活动的信息、偏好和兴趣。</p>
          <p>b. 自动生成的信息：在使用我们的服务器和相关服务时，我们将自动生成有关您的信息，例如您的服务器 IP 地址、使用服务器的日期和时间、您访问的服务器资源等。</p>
          <p>c. 我们还收集有关您的设备的信息，例如您的游戏客户端类型和版本、操作系统等。</p>
          <p>d. 地理位置数据：我们还可能收集有关您的位置的信息，例如您的国家、州或城市， 其来自你的IP地址。</p>
        </li>
        <li>2.我们使用收集到的信息来：
          <p>a. 提供、维护和改善我们的服务器和相关服务。</p>
          <p>b. 分析您使用服务器的模式，以便我们改进服务器。</p>
          <p>c. 预防、响应潜在的欺诈、滥用或安全滥用。</p>
          <p>d. 向您发送警报、通知或关于您服务器或相关服务的消息。</p>
        </li>
        <li>3.我们共享信息的方式：
          <p>我们不会将您提供的信息共享给第三方，除非您允许或根据法律要求。</p>
        </li>
        <li>4.安全
          <p>我们使用适当的安全措施来保护收集到的信息。然而，请务必注意，没有安全是完美的，您不应该将您的密码分享给任何人。您负责维护您的帐户和使用的设备的安全性。</p>
        </li>

        <h1>一般条款</h1>
        <p>您当地的法律可能会赋予您本 EULA 无法更改的权利；如果是这样情况，本 EULA 将在法律允许的范围内适用。</p>
        <p>如果有适当理由，我们可能会不时更改本 EULA，例如更改我们的游戏、惯例或法律义务。但这些更改只在法律允许的范围内生效。在后一种情况下，我们会在更改生效前通知您，方法是在我们的网站上张贴通知，或者通过其他合理的方式。虽然在这方面我们会尽量做到公平，但有时法律会有变化，或者有人会做一些影响游戏的其他用户的事情，因此我们需要禁止这种行为。</p>
        <p>如果您向我们提出有关 Minecraft 的建议，则该建议是免费的，我们没有义务接受或考虑。这意味着我们可以通过所需的任何方式使用或不使用您的建议，且不必向您支付报酬。如果您认为我们应为您的建议支付报酬，那么除非您提前告诉我们您希望得到报酬并且我们已经以书面形式回复，要求您提交该建议，否则请不要将您的建议告诉我们。</p>

        <h2>关于违反我们的Eula</h2>
        <p>一般情况下，您可能会被禁止访问我们的社区。</p>
        <p>在违反法律的情况下您一定会被移交警方。</p>
    </article>
		</main>
	</div>
  );
}

export default index;
